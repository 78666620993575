<template>
	<div class="mt-30 text-left pb-30">
		<div class="under-line pb-10 flex-row">
			<h3 class="flex-1">NFT 컬렉션</h3>
			<div>
				<button
					class="btn-inline btn-primary-outline radius-20"
					@click="toList"
				>목록</button>
			</div>
		</div>

		<div class="mt-30">
			<div class="flex-row">
				<div
					class=" width-480 mr-30"
				>
					<div class="union-340 position-relative">
						<img
							:src="item.nft_card_img_url"
							@error="$event.target.src=require('@/assets/image/@noimage.png')"
							class="width-100"
						/>

						<div
							v-if="is_staking"
							class="bg-lock position-absolute-full radius-20"
						></div>
					</div>
				</div>
				<div
					class="flex-1 flex-column justify-space-between"
				>
					<dl>
						<dt class="h3 color-secondary under-line pb-20">{{ item.nft_card_name}}</dt>
						<dd class="mt-20">{{ item.nft_card_desctn}}</dd>
					</dl>
					<div class="flex-1 mt-30">
						<h3 class="under-line pb-20">NFT 정보</h3>
						<dl class="flex-row justify-space-between mt-20">
							<dt>소유자</dt>
							<dd class="mt-20">{{ item.owner_nickname }}</dd>
						</dl>
						<dl class="flex-row justify-space-between mt-10">
							<dt>컨트렉트 주소</dt>
							<dd class="mt-10">{{ item.smartcontract_address }}</dd>
						</dl>
						<dl class="flex-row justify-space-between mt-10">
							<dt>토큰 ID</dt>
							<dd class="mt-10">{{ item.nft_token_id }}</dd>
						</dl>
					</div>
					<div
						class="flex-row justify-space-between">
						<div>
							<button
								class="btn-inline btn-primary-outline radius-20"
								@click="toList"
							>STAKING 목록</button>
						</div>
						<button
							v-if="is_staking"
							class="btn-inline btn-primary radius-20"
							@click="onStaking(false)"
						>스테이킹 해제</button>
						<button
							v-else
							class="btn-inline btn-primary radius-20"
							@click="onStaking(true)"
						>스테이킹</button>
					</div>
				</div>
			</div>
		</div>

		<div class="mt-30 flex-row">
			<mafia038
				:user="user"
				:card="item"

				class="flex-1 mr-30"
			></mafia038>
		</div>

		<mafia024
			v-if="is_024"
			:user="user"
			:nft_card="item"

			@cancel="is_024 = !is_024"
			@click="onPin"
		></mafia024>

		<mafia025
			v-if="is_025"
			:nft_card="item"
			:user="user"

			@cancel="is_025 = !is_025"
			@click="onPin"
		></mafia025>

		<mafia03347
			v-if="is_03347"
			:item="item"

			@cancel="is_03347 = !is_03347"
			@click="toStaking"
		></mafia03347>

		<PIN
			v-if="is_pin"
			:options="pin_option"
			:user="user"
			style="position: fixed; left: 0; top: 0; width: 100%; z-index: 1002"

			@click="pinClick"
			@cancel="pinCancel"
		></PIN>

		<wallet_otp
			v-if="is_otp"
			style="position: fixed; left: 0; top: 0; width: 100%; z-index: 101; background-color: white"

			@click="otpClick"
			@cancel="is_otp = false"
		></wallet_otp>


		<PopupConfirm
			v-if="is_confirm"
			@click="toOtp"
			@cancel="is_confirm = false"
		>
			<template v-slot:title>이중인증 안내</template>
			<template
				v-slot:main-txt
			>이중인증 미등록 상태입니다</template>
			<template
				v-slot:sub-txt
			>Google OTP 설정 후 이용가능합니다.</template>
			<template
				v-slot:name-cancel
			>취소</template>
			<template
				v-slot:name-confirm
			>설정하기</template>
		</PopupConfirm>
	</div>
</template>

<script>
import PIN from "@/view/Auth/mafia007";

import mafia024 from "@/view/Nft/mafia024";
import mafia025 from "@/view/Nft/mafia025";
import mafia03347 from "@/view/Nft/mafia033-47";
import wallet_otp from "@/view/Wallet/wallet_otp";
import PopupConfirm from "@/view/Layout/PopupConfirm";
import Mafia038 from "@/view/Staking/mafia038";

export default {
	name: 'mafia0362'
	, props: ['user']
	, components: {Mafia038, wallet_otp, PIN, mafia03347, mafia024, mafia025, PopupConfirm}
	, data: function(){

		return {
			program: {
				name: '유니온 카드'
				, title: 'UNION CARD'
				, from: 'mafia018'
			}
			, is_024: false
			, is_025: false
			, is_03347: false
			, is_pin: false
			, pin_option: {
				pin_type: 'check'
			}
			, is_otp: false
			, item: {
				nft_card_img_url: ''
			}
			, type: ''
			, item_on: {

			}
			, card_type: this.$route.params.card_type
			, pin: ''
			, receive_wallet_address: ''
			, is_confirm: false
			, card_type_code: {
				'NF00200041': 'CONSIGLIERE'
				, 'NF00200042': 'ACCOUNTANT'
				, 'NF00200043': 'MADAM'
				, 'NF00200044': 'SPY'
			}
			, items_present: []
			, items_history: []
		}
	}
	,computed: {

		is_present: function(){
			let t = false

			if(this.item.main_card_fg || this.item.utilty_card_mount_fg == 'Y' || this.item.stakg_fg == 'Y'){
				t = true
			}
			return t
		}
		,is_widthdrawal: function(){
			let t = false
			if(this.item.main_card_fg || this.item.utilty_card_mount_fg == 'Y' || this.item.stakg_fg == 'Y'){
				t = true
			}
			return t
		}
		, is_staking: function(){
			let t = false
			if(this.item.stakg_fg == 'Y'){
				t = true
			}
			return t
		}
	}
	, methods: {
		postWithdrawal: async function(otp){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_nft_withdrawal
					, data: {
						member_number: this.user.member_number
						, pinnumber: this.pin
						, gogle_otp_certfc_number: otp
						, wallet_address: this.item_on
						, nft_card_number: this.$route.params.id
						, nft_card_div_code: this.item.nft_card_div_code
					}
					, type: true
				})

				if(!result.success){
					throw result.message
				}

				this.$bus.$emit('notify',  { type: 'success', message: 'NFT카드 출금이 완료되었습니다', config: { bottom: 0}})
				this.$emit('goBack')

			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify',  { type: 'success', message: e, config: { bottom: 0}})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postPresent: async function(){

			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_present
					, data: {
						member_number: this.user.member_number
						, gift_member_number: this.item_on.friend_member_number
						, nft_card_number: this.$route.params.id
						, pinnumber: this.pin
					}
					, type: true
				})

				if(!result.success){
					throw result.message
				}

				this.$bus.$emit('notify',  { type: 'success', message: '선물하기가 완료되었습니다', config: { bottom: 0}})
				this.$emit('goBack')

			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify',  { type: 'success', message: e, config: { bottom: 0}})
			}finally {
				this.$bus.$emit('on', false)
			}

		}
		, postStaking: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_staking
					, data: {
						member_number: this.user.member_number
						, nft_card_number: this.$route.params.id
					}
					, type: true
				})

				if(result.success){
					this.$bus.$emit('notify',  { type: 'success', message: '스테이킹 처리가 완료되었습니다', config: { bottom: 0}})
					await this.getMyCard()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify',  { type: 'error', message: e, config: { bottom: 0}})
			}finally {
				this.is_03347 = false
				this.$bus.$emit('on', false)
			}
		}

		, postUnStaking: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_unstaking
					, data: {
						member_number: this.user.member_number
						, nft_card_number: this.$route.params.id
						, pinnumber: this.pin
					}
					, type: true
				})

				if(result.success){
					this.$bus.$emit('notify',  { type: 'success', message: '스테이킹 해제가 완료되었습니다', config: { bottom: 0}})
					await this.getMyCard()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify',  { type: 'error', message: e, config: { bottom: 0}})
			}finally {
				this.is_03347 = false
				this.$bus.$emit('on', false)
			}
		}
		,onPin: function(item){
			this.item_on = item
			this.is_pin = true
			this.pin_option = {
				pin_type: 'check'
				, is_can_cancel: true
				, div: this.type
			}
			this.is_024 = false
			this.is_025 = false
			this.is_03347 = false
		}
		,pinClick: function(pin){
			this.is_pin = false
			this.pin = pin

			if (this.pin_option.div == 'staking') {
				if(this.item.stakg_fg == 'Y'){
					this.postUnStaking()
				}else{
					this.postStaking()
				}
			} else {
				this.onOtp()
			}
		}
		,pinCancel: function(){
			this.is_pin = false
			this.pin_option= {}
			this.item_on = {}
		}
		, getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: this.$api_url.api_path.get_nft_detail
					,data: {
						member_number: this.user.member_number
						, nft_card_number: this.$route.params.id
					}
					, type: true
				})

				if(result.success){
					this.item = result.data
					console.log(this.item)
					if(this.item.main_card_fg == 'Y'){
						this.$set(this.item, 'main_card_fg', true)
						this.item.main_card_fg = true
					}else{
						this.item.main_card_fg = false
						this.$set(this.item, 'main_card_fg', false)
					}

					if(this.item.nft_card_img_url){
						setTimeout(() => {
							// window.atvImg()
						}, 100)
					}
				}else{
					this.$bus.$emit('notify',  { type: 'error', message: result.message, config: { bottom: 0}})
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: this.$language.common.error, config: { bottom: 0}})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getPresentData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_present_history
					, data: {
						member_number: this.user.member_number
						, nft_card_number: this.item.nft_card_number
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
					}
				})
				if(result.success){
					this.items_present = result.data.nft_card_gift_transaction_list
				}else{
					this.$bus.$emit('notify',  { type: 'error', messages: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getHistoryData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_nft_history
					, data: {
						member_number: this.user.member_number
						, nft_card_number: this.item.nft_card_number
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
					}
				})
				if(result.success){
					this.items_history = result.data.nft_card_transaction_list
				}else{
					this.$bus.$emit('notify',  { type: 'error', messages: result.message})
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPresent: function(){
			if(this.user.gogle_otp_reg_fg == 'Y'){
				this.type = 'present'
				this.is_025 = true
			}else{
				this.is_confirm = true
			}
		}
		, onWithdrawal: function(){
			if(this.user.gogle_otp_reg_fg == 'Y'){
				this.type = 'withdrawal'
				this.is_024 = true
			}else{
				this.is_confirm = true
			}
		}
		, toStaking: function(){
			if(this.pin_option.pin_type == 'check'){
				this.onPin()
			}else{
				this.postStaking()
			}
		}
		, onStaking: function(type){
			if(type){
				this.pin_option = {
					pin_type: 'not'
				}
				this.is_03347 = true
			}else{
				this.type = 'staking'
				this.pin_option = {
					pin_type: 'check'
					, div: this.type
				}
				this.is_03347 = true
			}
		}
		, onOtp: function(){
			this.is_otp = true
		}
		,getMyCard: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: this.$api_url.api_path.get_nft_detail
					,data: {
						member_number: this.user.member_number
						, nft_card_number: this.$route.params.id
					}
					, type: true
				})

				if(result.success){
					this.item = result.data
					if(this.item.main_card_fg == 'Y'){
						this.$set(this.item, 'main_card_fg', true)
						this.item.main_card_fg = true
					}else{
						this.item.main_card_fg = false
						this.$set(this.item, 'main_card_fg', false)
					}
				}else{
					this.$bus.$emit('notify',  { type: 'error', message: result.message, config: { bottom: 0}})
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: this.$language.common.error, config: { bottom: 0}})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, otpClick: function(otp){

			this.is_otp = false

			if(this.pin_option.div == 'withdrawal'){
				this.postWithdrawal(otp)
			}else if(this.pin_option.div == 'present'){
				this.postPresent()
			}else if(this.pin_option.div == 'staking'){

				if(this.item.stakg_fg == 'Y'){
					this.postUnStaking(otp)
				}else{
					this.postStaking(otp)
				}
			}

			this.pin_option= {}
		}
		, toOtp: function(){
			this.$bus.$emit('to', { name: 'mafia1113'})
		}
		, toList: function(){
			this.$bus.$emit('to', { name: 'staking'})
		}
	}
	, async created() {
		this.$emit('onLoad', this.program)
		await this.getData()
	}
}
</script>

<style>
.card_info { float: none; text-align: right;}
</style>